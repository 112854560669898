import React, { useState } from "react";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const Question = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  // custom styles for animation
  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  const data = [
    {
      id: 1,
      title: "What is the purpose of the Gizmo 360 platform?",
      content:
        "Gizmo 360 is a one-stop destination for electronics and information on consumer technology, offering a wide range of products from small and medium-sized local offline consumer electronics retailers.",
    },
    {
      id: 2,
      title:
        "How does Gizmo B2B help small retailers reach a wider customer base?",
      content:
        "Gizmo B2B solutions offer a comprehensive e-commerce solution that allows small retailers to expand their customer reach through online sales channels. Additionally, we're building a community where small businesses can connect with tech enthusiasts, influencers, and shoppers, expanding their reach even further.",
    },
    {
      id: 3,
      title: "What kind of community is Gizmo 360 building?",
      content:
        "Gizmo 360 is building a community where small businesses can connect with tech enthusiasts, influencers, and shoppers. This community will serve as a platform for everyone to engage and benefit from each other.",
    },
    {
      id: 4,
      title:
        " What makes Evolvum Tech’s platform different from other e-commerce platforms?",
      content:
        "Evolvum Tech is dedicated to helping small and medium-scale retailers adapt to the digital world, rather than just providing a platform for them to sell their products. Additionally, we're building a community where small businesses can network and engage with tech enthusiasts, influencers, and shoppers, which sets us apart from other e-commerce platforms.        ",
    },
    {
      id: 5,
      title: " How does Evolvum Tech prioritize customer satisfaction?",
      content:
        "Our platform is designed to provide a seamless and user-friendly experience for both retailers and shoppers. Additionally, we're constantly working to improve our platform and services based on customer feedback to ensure that we are meeting their needs.",
    },
  ];

  function Icon({ id, open }) {
    return (
      <>
        {id == open ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 font-bold text-[#004AAD]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 font-bold text-[#004AAD]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        )}
      </>
    );
  }
  return (
    <div className="py-[40px] md:py-[70px]" id="FAQ">
      <section className="py-10 bg-white sm:py-20 !pb-10">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h1 className="text-[31px] font-[600] text-center font-inter capitalize">
              Frequently asked questions
            </h1>
          </div>

          <div className="max-w-4xl mx-auto mt-8 space-y-4 md:mt-10">
            {data.map((i) => (
              <div key={i.id} className="relative inline-flex mt-1 group">
                <div className="absolute transitiona-all duration-1000 opacity-20 -inset-px bg-gradient-to-r from-[#44beff27] via-[#ff44ec1f] to-[#ff665e21] rounded-xl blur-lg group-hover:opacity-80 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
                <div className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50 rounded-lg">
                  <Accordion
                    open={open === i.id}
                    className="relative transition-all bg-white duration-200 rounded-lg"
                    animate={customAnimation}
                    icon={<Icon id={i.id} open={open} />}
                  >
                    <AccordionHeader
                      className="flex px-5 text-left text-base sm:text-lg font-semibold text-black second_Head_content"
                      onClick={() => handleOpen(i.id)}
                    >
                      {i.title}
                    </AccordionHeader>
                    <AccordionBody className="px-4 !pt-0 !pb-5 sm:px-6 text-[#616C87]">
                      {i.content}
                    </AccordionBody>
                  </Accordion>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Question;
