import React from "react";
import priduct1 from "../../assets/product/product1.svg";
import priduct2 from "../../assets/product/product2.svg";
import priduct3 from "../../assets/product/product3.svg";
import priduct4 from "../../assets/product/product4.svg";
import priduct5 from "../../assets/product/product5.svg";
import priduct6 from "../../assets/product/product6.svg";
import priduct7 from "../../assets/product/product7.svg";
import priduct8 from "../../assets/product/product8.svg";

const Brands = () => {
  return (
    <section class="py-12 bg-[#004AAD] sm:py-16 lg:py-20">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
          <h2 class="text-2xl font-bold text-white font-pj">
            Trusted by nearly 50+ Brands
          </h2>
        </div>

        <div class="mt-8 sm:mt-12">
          <svg
            class="w-auto h-4 mx-auto text-white"
            viewBox="0 0 172 16"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 11 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 46 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 81 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 116 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 151 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 18 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 53 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 88 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 123 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 158 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 25 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 60 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 95 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 130 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 165 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 32 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 67 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 102 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 137 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 172 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 39 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 74 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 109 1)"
            />
            <line
              y1="-0.5"
              x2="18.0278"
              y2="-0.5"
              transform="matrix(-0.5547 0.83205 0.83205 0.5547 144 1)"
            />
          </svg>
        </div>

        <div class="max-w-5xl mx-auto mt-8 space-y-6 sm:space-y-8 xl:space-y-12 sm:mt-12">
          <div class="grid items-center grid-cols-1 sm:grid-cols-2 gap-y-6 sm:gap-y-8 md:grid-cols-4 place-items-center gap-x-8">
            <img
              class="object-contain w-48 h-10 mx-auto"
              src={priduct1}
              alt=""
            />
            <img
              class="object-contain w-40 h-10 mx-auto"
              src={priduct2}
              alt=""
            />
            <img
              class="object-contain w-48 mx-auto h-9"
              src={priduct3}
              alt=""
            />
            <img
              class="object-contain w-48 h-10 mx-auto"
              src={priduct4}
              alt=""
            />
          </div>

          <div class="grid items-center grid-cols-1 sm:grid-cols-2 gap-y-6 sm:gap-y-8 md:grid-cols-4 place-items-center gap-x-8">
            <img
              class="object-contain w-48 h-10 mx-auto"
              src={priduct5}
              alt=""
            />
            <img
              class="object-contain w-48 h-10 mx-auto"
              src={priduct6}
              alt=""
            />
            <img
              class="object-contain w-48 h-10 mx-auto"
              src={priduct7}
              alt=""
            />
            <img
              class="object-contain w-48 h-10 mx-auto"
              src={priduct8}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
