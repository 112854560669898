import React from "react";
import { Link } from "react-scroll";
import ContentImg2 from "../../assets/content image/contentImg2.svg";
import Instagram from "../../assets/svg/Instagram.svg";
import LinkedIn from "../../assets/svg/LinkedIn.svg";
import Facebook from "../../assets/svg/Facebook.svg";
import Twitter from "../../assets/svg/Twitter.svg";

const Footer = () => {
  return (
    <footer>
      <div className="px-4 mx-auto sm:px-6 lg:px-8 bg-[#004AAD0D] w-full h-full py-20">
        <div className="max-w-2xl mx-auto text-center">
          <h5 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
            Drop us a line or two, we are open for creative minds and
            collaborations!
          </h5>

          <div className="relative inline-flex mt-12 group">
            <a
              href="mailto:info@evolvum.in"
              target="_blank"
              title=""
              className="relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-[#004AAD] font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#004aad]"
              role="button"
            >
              {" "}
              Contact us
            </a>
          </div>
        </div>
      </div>
      <section class="py-10 bg-white  sm:pt-16 lg:pt-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-8">
            <div class="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
              <img class="w-auto h-[24px]" src={ContentImg2} alt="" />

              <p class="text-base leading-relaxed text-gray-600 mt-7">
                <span className="text-[#616C87]">Registered Office:</span>{" "}
                <br /> EVOLVUM TECH PRIVATE LIMITED
                <br /> CIN: U51909KA2023PTC170332 <br />
                No. 677, 1st Floor, Desk no 105A, 27 Main, <br /> 13th Cross, HSR
                Layout, Sector 1, Bangalore, Karnataka, India - 560102
              </p>
            </div>

            <div>
              <ul class="mt-2 space-y-4">
                <p class="text-sm font-semibold tracking-widest text-[#616C87]">
                  Links
                </p>

                <li>
                  <a
                    href="#"
                    title=""
                    class="flex text-base font-normal text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                  >
                    Home
                  </a>
                </li>

                <li>
                  <Link
                    to="about"
                    smooth={true}
                    duration={2000}
                    className={`text-[16px] font-normal  transition-all duration-200 rounded hover:text-[#004aad]`}
                  >
                    About
                  </Link>
                </li>

                <li>
                  <Link
                    to="Solution"
                    smooth={true}
                    duration={2000}
                    offset={100}
                    className={`text-[16px] font-normal  transition-all duration-200 rounded hover:text-[#004aad]`}
                  >
                    Solution
                  </Link>
                </li>

                <li>
                  <Link
                    to="FAQ"
                    smooth={true}
                    duration={2000}
                    className={`text-[16px] font-normal transition-all duration-200 rounded hover:text-[#004aad] `}
                  >
                    FAQ
                  </Link>
                </li>
               
              </ul>
            </div>

            <div>
              <ul class="mt-2 space-y-4">
                <li>
                  <p class="text-sm font-semibold tracking-widest text-[#616C87]">
                    Others
                  </p>
                </li>

                <li>
                <Link
                    to="Testimonial"
                    smooth={true}
                    duration={2000}
                    className={`text-[16px] font-normal transition-all duration-200 rounded hover:text-[#004aad]`}
                  >
                    Testimonial
                  </Link>
                </li>

                <li>
                  <a
                    href="#"
                    title=""
                    class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                  >
                    {" "}
                    Terms & Conditions{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    title=""
                    class="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <ul class="mt-2 space-y-4">
                <p class="text-sm font-semibold tracking-widest text-[#616C87]">
                  Contact
                </p>

                <li>
                  <p class="flex  text-sm text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600">
                    <span className="text-[#616C87]">Ph.No:</span>+91 9575595775
                  </p>
                </li>

                <li>
                  <p class="flex text-sm text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600">
                    <span className="text-[#616C87]">Email:</span>{" "}
                    info@evolvum.in
                  </p>
                </li>

                <ul class="flex items-center space-x-3 mt-9">
                  <li>
                    <a
                      href="https://twitter.com/evolvumtech"
                      target="_blank"
                      title=""
                      className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                      rel="noopener"
                    >
                      <img src={Twitter} alt="twitter" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.facebook.com/evolvumtech"
                      target="_blank"
                      title=""
                      className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                      rel="noopener"
                    >
                      <img src={Facebook} alt="fb" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/evolvumtech"
                      target="_blank"
                      title=""
                      className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                      rel="noopener"
                    >
                      <img src={Instagram} alt="insta" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/evolvumtech"
                      target="_blank"
                      title=""
                      className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                      rel="noopener"
                    >
                      <img src={LinkedIn} alt="li" />
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          <hr class="mt-16 mb-10 border-gray-200" />

          <p class="text-sm text-center text-gray-600">
            © Copyright 2023, All Rights Reserved by Evolvum Tech Private
            Limited | Powered by <a href="https://www.peacockindia.in">Peacockindia</a> {" "}
          </p>
        </div>
      </section>
    </footer>
  );
};
export default Footer;
