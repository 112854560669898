import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const index = () => {
  return (
    <div className="backgroundAnimation bg-black w-full lg:h-[720px] xs:h-[100vh] xss:h-[100vh] s:h-[100vh] ss:h-[100vh] flex justify-center items-center ">
      <div className=" bg-[#00000086] lg:w-[999px] lg:h-[270px] lgs:w-[689px] lgs:h-[226px] md:w-[589px] md:h-[226px] xs:w-[340px] xs:h-[250px]  xss:h-[200px] xss:w-[90%] s:w-[90%] s:h-[200px] ss:w-[90%] ss:h-[200px]  flex items-center justify-center flex-col pt-4 rounded-lg">
        <p className=" font-normal lg:text-4xl xs:text-[20px] xss:text-[20px] xss:px-4 xss:leading-[25px] text-white text-center lg:leading-[55px] xs:leading-[30px] lgs:text-[22px] ss:text-[14px] ss:px-4 s:text-[16px]">
        Transform Your Business with the Power of Technology, Community, and Commerce.
        </p>
        <p className="text-[#E5E7EB] lg:w-[80%] mt-4 lg:text-[20px] xs:w-[90%] xss:text-[14px] xss:px-4 ss:text-[12px] ss:w-[80%]  text-center">
          Empowering local electronics retailers, connecting tech enthusiasts,
          and creating a community that celebrates innovation!
        </p>
      </div>
    </div>
  );
};

export default index;
