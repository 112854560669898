import React, { useState } from "react";
import Brands from "../../components/Brands";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Question from "../../components/Questions";
import ContentImg1 from "../../assets/content image/contentImg1.png";
import ContentImg2 from "../../assets/content image/contentImg2.svg";
import ContentImg3 from "../../assets/content image/contentImg3.png";
import ContentImg4 from "../../assets/content image/contentImg4.png";
import ContentImg5 from "../../assets/content image/contentImg5.png";
import Profile from "../../assets/content image/profile.png";
import Profile1 from "../../assets/content image/profile1.png";
import Profiles from "../../assets/content image/profiles.png";
import contentImageMobile from "../../assets/content image/contentImageMobile.png";

import Arrow from "../../assets/arrow.svg";

const Home = () => {

  return (
    <div >
      <Header />
      <section
        class="py-12 bg-[#004AAD0D]
      sm:py-16 lg:py-20"
      >
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-2 md:gap-16 gap-y-10">
            <div class="-mx-4 sm:mx-0">
              <img
                className="profile w-full max-w-md mx-auto"
                src={ContentImg1}
                alt="ContentImg1"
              />
               <img
                className="profile1 w-full max-w-md mx-auto"
                src={contentImageMobile}
                alt="ContentImg"
              />
            </div>

            <div class="xl:pr-24">
              <img className="w-auto h-[24px]" src={ContentImg2} alt="" />

              <blockquote class="mt-8 md:mt-8">
                <p class="text-xl font-medium leading-relaxed text-gray-900 font-pj">
                  "At Evolvum Tech Pvt Ltd, we are committed to helping small
                  and medium-sized retailers adapt to the digital world. Our
                  platform provides these retailers with the tools they need to
                  compete in the e-commerce space. We're also building a
                  community where small businesses can network with tech
                  enthusiasts, influencers, and shoppers. Together, we're
                  creating a new era of electronics retail. Join us on this
                  journey and experience the future of buying and selling
                  electronics."
                </p>
              </blockquote>

              {/* <blockquote class="mt-5 md:mt-7">
                <p class="text-lg font-normal leading-relaxed text-gray-900 font-pj">
                  “You made it so simple. My new site is so much faster and
                  easier to work with than my old site. I just choose the page,
                  make the change.”
                </p>
              </blockquote> */}

              <p class="mt-5 text-base font-bold text-gray-400 md:mt-7 font-pj">
                <span className="text-[#004aad]">Anbu Chelian P E,</span>
                <br />
                Founder & CEO at Evolvum Tech Private Limited.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Brands />

      <section class="py-12 bg-gray-50 sm:py-16 lg:py-20" id="about">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-16 xl:gap-x-36">
            <div class="md:order-2">
              <img class="w-full max-w-sm mx-auto" src={Profiles} alt="" />
            </div>

            <div class="text-center md:order-1 md:text-left">
              <h2 class="text-3xl font-bold text-[#004AAD] sm:text-4xl xl:text-5xl font-pj">
                About Us
              </h2>
              <p class="text-lg text-start font-medium text-gray-900 mt-7 font-pj">
                "Our mission is to provide small and medium-sized offline
                electronics retailers with a comprehensive e-commerce platform
                to increase sales and reach a wider customer base. Our platform
                offers a seamless and user-friendly experience for both
                retailers and shoppers, fostering a community of tech
                enthusiasts. We are dedicated to driving the future of online
                retail through technology and customer satisfaction."
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="py-12 bg-[#004AAD0D] sm:py-16 lg:py-20" id="Solution">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="mt-4 text-3xl font-bold text-[#004AAD] sm:text-4xl xl:text-5xl font-pj">
              Our Solutions
            </h2>
            <p class="text-lg mt-4 font-medium text-gray-600 font-pj">
              Innovative solutions to move your business forward.
            </p>
          </div>

          <div class=" grid grid-cols-1 mt-10 text-center md:mt-20 md:max-w-full sm:max-w-sm lg:grid-cols-3 sm:mx-auto gap-y-11 md:grid-cols-2 md:gap-x-6 lg:gap-x-11 xl:gap-x-10 md:text-left">
            <div>
              <h3 class="text-2xl  lg:w-full min-h-[80px] font-bold text-start text-gray-900 font-pj">
                Transform your Business with
                <span className="text-[#004aad]"> Gizmo B2B </span>
                for Retail Stores.
              </h3>
              <p class="mt-4 text-base lg:w-full leading-7 text-start text-gray-900 font-pj">
                Track Orders, Manage Inventory and Connect with your local
                customers.
              </p>

              <img
                class="object-cover w-full mx-auto mt-10 rounded-xl md:mx-0 lg:pt-9 "
                src={ContentImg3}
                alt="ContentImg3"
              />
              <ul class="text-base leading-7 text-[#616C87] mt-7 ml-6 font-pj list-disc">
                <li className=" text-start"> Digital Storefront</li>
                <li className=" text-start"> Analytics & Reporting</li>
                <li className=" text-start"> Inventory Management</li>
                <li className=" text-start"> Last mile Delivery</li>
                <li className=" text-start"> Paid Priority Listings</li>
                <li className=" text-start"> Customer Management</li>
              </ul>

              <button className="w-full flex items-center justify-center bg-[#004aad] text-white py-3 rounded-xl mt-6 font-semibold px-3 ">
                Get Started
                <img src={Arrow} alt="arrow" className="mx-4" />
              </button>
            </div>

            <div>
              <h3 class="text-2xl font-bold text-start   text-gray-900 font-pj">
                Unleash your creative potential and Monetise your Passion with{" "}
                <span className="text-[#004aad]"> Gizmo Studio</span> for the
                Creators.
              </h3>
              <p class="mt-4 text-base leading-7 text-start xl:min-h-[100px] lg:min-h-[90px] lgm:min-h-[68px] lgs:min-h-[65px] text-gray-900 font-pj">
                Create, Grow & Monetize your passion.
                <br />
              
              </p>

              <img
                class="object-cover w-full mx-auto mt-10 xs:mt-3 rounded-xl md:mx-0"
                src={ContentImg4}
                alt="ContentImg3"
              />
              <ul class="text-base leading-7 text-[#616C87] mt-7 ml-6 font-pj list-disc">
                <li className=" text-start"> Creators Incentive Programs</li>
                <li className=" text-start"> Content Monetization</li>
                <li className=" text-start"> Affiliate Commissions</li>
                <li className=" text-start"> Fan Membership</li>
                <li className=" text-start"> Creator Tools</li>
                <li className=" text-start"> Loyalty Programs</li>
              </ul>
              {/* <div className="relative inline-flex mt-2 group w-full">
                        <div
                            className="absolute duration-1000 transform rotate-180 transitiona-all opacity-70 -inset-1 rounded-xl blur-lg filter group-hover:opacity-100 group-hover:-inset-1.5 group-hover:duration-200"
                            style={{background: 'linear-gradient(90deg, #004aad -0.55%, #004aad 22.86%, #004aad 48.36%, #004aad 73.33%, #ebff70 99.34%)'}}
                        ></div>
                
              </div> */}
              <button className="w-full flex items-center justify-center bg-[#004aad] text-white py-3 rounded-xl mt-6 font-semibold px-3 ">
                Get Started
                <img src={Arrow} alt="arrow" className="mx-4" />
              </button>
            </div>

            <div>
              <h3 class="text-2xl font-bold text-start text-gray-900 font-pj">
                Discover the Latest Consumer tech Trends, Products, and Expert
                Opinions in <span className="text-[#004aad]">Gizmo 360</span>{" "}
                Social Shopping.
              </h3>
              <p class="mt-4 text-base leading-7 text-start xl:min-h-[70px] lg:min-h-[60px] text-gray-900 font-pj">
                Consumer Tech Social Shopping App.
                <br />
              </p>

              <img
                class="object-cover w-full mx-auto mt-10 xs:mt-3 rounded-xl md:mx-0"
                src={ContentImg5}
                alt="ContentImg3"
              />
              <ul class="text-base leading-7 text-[#616C87] mt-7 ml-6 font-pj list-disc">
                <li className=" text-start">Reviews</li>
                <li className=" text-start"> Product Ratings</li>
                <li className=" text-start"> Shopping </li>
                <li className=" text-start"> Forums</li>
                <li className=" text-start"> Private Chats</li>
                <li className=" text-start"> Tools</li>
              </ul>
              <button className="w-full flex items-center justify-center bg-[#004aad] text-white py-3 rounded-xl mt-6 font-semibold px-3 ">
                Get Started
                <img src={Arrow} alt="arrow" className="mx-4" />
              </button>
            </div>
          </div>
        </div>
      </section>
      <Question />
      <section class="py-12 bg-[#004aad] sm:py-16 lg:py-20 " id="Testimonial">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="text-left md:text-center">
            <h2 class="text-3xl text-center font-bold text-white sm:text-4xl xl:text-5xl font-pj">
              Testimonials
            </h2>
          </div>

          <div class="flex items-center justify-between lg:flex-nowrap xs:flex-wrap max-w-7xl xs:mx-3  mx-auto mt-8 md:grid-cols-2 md:mt-20 md:gap-16 xss:grid-cols-1 xss:flex-col lg:flex-row s:flex-col ss:flex-col">
            <div>
              {/* <p class="text-xl font-bold text-white font-pj">
                The best UI Kit for developers. So easy to implement and
                publish.
              </p> */}
              <blockquote class="mt-5 xs:mt-10">
                <p class="text-lg font-semibold leading-relaxed text-gray-200 font-pj">
                  "Gizmo B2B can be a game-changer for my business. The platform
                  will help me reach a much wider customer base. I'm so grateful
                  for the opportunity to sell my products online and connect
                  with tech enthusiasts from all over the world."
                </p>
              </blockquote>
              <div class="flex items-center mt-6">
                <img
                  class="flex-shrink-0 object-cover rounded-full w-11 h-11"
                  src={Profile}
                  alt=""
                />
                <div class="ml-4">
                  <p class="text-base font-bold text-white font-pj">
                    Theresa Webb
                  </p>
                  <p class="text-sm font-pj text-[#D0D6DF] mt-0.5">
                    Small Business Owner
                  </p>
                </div>
              </div>
            </div>

            <div>
              {/* <p class="text-xl font-bold text-white font-pj">
                Rareblocks helps you optimize for engagement.
              </p> */}
              <blockquote class="mt-5 xs:mt-10">
                <p class="text-lg font-semibold leading-relaxed text-gray-200 font-pj">
                  "As an influencer, I appreciate the support and opportunities
                  Gizmo Creator’s Program is providing me. The platform makes it
                  easy to collaborate with small businesses and promote good
                  products to my followers. I would highly recommend it to other
                  influencers looking to grow."
                </p>
              </blockquote>
              <div class="flex items-center mt-6">
                <img
                  class="flex-shrink-0 object-cover rounded-full w-11 h-11"
                  src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/7/avatar-male.png"
                  alt=""
                />
                <div class="ml-4">
                  <p class="text-base font-bold text-white font-pj">
                    Dianne Russell
                  </p>
                  <p class="text-sm font-pj text-[#D0D6DF] mt-0.5">
                    Influencer
                  </p>
                </div>
              </div>
            </div>

            <div>
              {/* <p class="text-xl font-bold text-white font-pj">
                The best UI Kit for developers. So easy to implement and
                publish.
              </p> */}
              <blockquote class="mt-5 xs:mt-10">
                <p class="text-lg font-semibold leading-relaxed text-gray-200 font-pj">
                  "I'm looking to find some amazing products on Gizmo360. I love
                  the community aspect of the platform, it is a great place to
                  find new and exciting products, and connect with other tech
                  enthusiasts."
                </p>
              </blockquote>
              <div class="flex items-center mt-6">
                <img
                  class="flex-shrink-0 object-cover rounded-full w-11 h-11"
                  src={Profile1}
                  alt=""
                />
                <div class="ml-4">
                  <p class="text-base font-bold text-white font-pj">Chitra</p>
                  <p class="text-sm font-pj text-[#D0D6DF] mt-0.5">
                    Online Shopper
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
