import React, { useState } from "react";
import Carousel from "../carousel";
import ContentImg2 from "../../assets/content image/contentImg2.svg";
import { Link } from "react-scroll";
import { IoMdClose } from "react-icons/io";

const Header = () => {
  const [nav, setNav] = useState("Home");
  const [open, setOpen] = useState(false);
  return (
    <section id="Home">
      <header className="bg-white">
        <div className="px-4 mx-auto sm:px-6 lg:px-6 max-w-7xl s:w-full ss:w-full">
          <div className="flex items-center justify-between h-16 lg:h-[72px]">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="p-2 -m-2 text-gray-900 transition-all duration-200 lg:hidden hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>

            <div className="flex items-center justify-center flex-shrink-0 mx-auto lg:ml-0">
              <div className="inline-flex">
                <img className="w-auto h-6 s:h-4 ss:h-3" src={ContentImg2} alt="" />
              </div>
            </div>

            <div className="hidden lg:flex lg:items-center lg:ml-16 xl:ml-24 lg:space-x-8  cursor-pointer">
              <Link
                to="Home"
                smooth={true}
                duration={2000}
                onClick={() => setNav("Home")}
                className={`text-[16px] font-medium ${
                  nav === "Home" ? "text-[#004AAD]" : "text-gray-900"
                }  transition-all duration-200 rounded hover:text-[#004aad]`}
              >
                Home
              </Link>

              <Link
                to="about"
                smooth={true}
                duration={2000}
                onClick={() => setNav("About")}
                className={`text-[16px] font-medium ${
                  nav === "About" ? "text-[#004AAD]" : "text-gray-900"
                } transition-all duration-200 rounded hover:text-[#004aad]`}
              >
                About
              </Link>

              <Link
                to="Solution"
                smooth={true}
                duration={2000}
                offset={100}
                onClick={() => setNav("Solution")}
                className={`text-[16px] font-medium ${
                  nav === "Solution" ? "text-[#004AAD]" : "text-gray-900"
                } transition-all duration-200 rounded hover:text-[#004aad]`}
              >
                Solution
              </Link>

              <Link
                to="FAQ"
                smooth={true}
                duration={2000}
                onClick={() => setNav("FAQ")}
                className={`text-[16px] font-medium ${
                  nav === "FAQ" ? "text-[#004AAD]" : "text-gray-900"
                } transition-all duration-200 rounded hover:text-[#004aad] `}
              >
                FAQ
              </Link>

              <Link
                to="Testimonial"
                smooth={true}
                duration={2000}
                onClick={() => setNav("Testimonial")}
                className={`text-[16px] font-medium ${
                  nav === "Testimonial" ? "text-[#004AAD]" : "text-gray-900"
                } transition-all duration-200 rounded hover:text-[#004aad]`}
              >
                Testimonial
              </Link>
            </div>

            <div className="flex items-center justify-end ml-auto space-x-2 sm:space-x-5">
              {/* <button
                type="button"
                className="p-2 -m-2 text-[#004AAD] border-2 border-solid px-6 rounded-xl border-[#004aad] transition-all duration-500 hover:bg-[#004aaf] hover:text-white"
              >
                Log in
              </button> */}
              <button
                type="button"
                className="p-2 -m-2 text-white border-2 border-solid px-6 s:px-3 s:p-1 ss:px-3 ss:p-0 rounded-xl border-[#004aad] transition-all duration-500 bg-[#004aaf]"
              >
                <a href="mailto:info@evolvum.in" target="_blank" className=" ss:text-[12px]">
                  Contact Us
                </a>
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className="relative py-2 bg-white sm:py-16 lg:py-2">
        <Carousel />
      </div>
      {open && (
        <>
          <div className="w-full bg-[#000] py-4 absolute top-0">
            <div
              className="w-full flex items-center justify-end my-2 -mx-3"
              onClick={() => setOpen(false)}
            >
              <IoMdClose
                size={25}
                className="text-white hover:text-[#004aad]"
              />
            </div>
            <ul className="text-white space-x-4 space-y-4 mt-4 ">
              <li></li>
              <li
                className="hover:text-[#004aad] cursor-pointer"
                onClick={() => setOpen(false)}
              >
                <Link
                  to="Home"
                  smooth={true}
                  duration={2000}
                  className={`text-[16px] font-medium transition-all duration-200 rounded hover:text-[#004aad]`}
                  onClick={() => setOpen(false)}
                >
                  Home
                </Link>
              </li>
              <li className="hover:text-[#004aad] cursor-pointer">
                <Link
                  to="about"
                  smooth={true}
                  duration={2000}
                  className={`text-[16px] font-medium transition-all duration-200 rounded hover:text-[#004aad]`}
                  onClick={() => setOpen(false)}
                >
                  About
                </Link>
              </li>
              <li className="hover:text-[#004aad] cursor-pointer">
                <Link
                  to="Solution"
                  smooth={true}
                  duration={2000}
                  offset={50}
                  className={`text-[16px] font-medium transition-all duration-200 rounded hover:text-[#004aad]`}
                  onClick={() => setOpen(false)}
                >
                  Solution
                </Link>
              </li>
              <li className="hover:text-[#004aad] cursor-pointer">
                <Link
                  to="FAQ"
                  smooth={true}
                  duration={2000}
                  offset={50}
                  className={`text-[16px] font-medium  transition-all duration-200 rounded hover:text-[#004aad] `}
                  onClick={() => setOpen(false)}
                >
                  FAQ
                </Link>
              </li>
              <li className="hover:text-[#004aad] cursor-pointer">
                <Link
                  to="Testimonial"
                  smooth={true}
                  duration={2000}
                  className={`text-[16px] font-medium transition-all duration-200 rounded hover:text-[#004aad]`}
                  onClick={() => setOpen(false)}
                >
                  Testimonial
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </section>
  );
};

export default Header;
